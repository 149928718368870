/*
 * The live copy of this file has comments removed, see the github version at
 *
 *   https://github.com/jperkin/www.perkin.org.uk/blob/master/css/screen.scss
 *
 * for more information.
 */

// I aim for a minimal design with minimal CSS.  Just enough to make it look
// how I want :)

// Macros
$color_background: 		#ffffff;
$color_maintext:   		#111111;
$color_bannertitle:		#000000;
$color_bannerlinks:		#444444;
$color_postinfo:		#444444;
$color_postinfo_a:		#444444;
$color_postinfo_a_visited:	#666666;
$color_postbody_a:		#4183c4;
$color_postbody_a_visited:	#3173b4;
$color_table:			$color_maintext;
$color_share:			$color_maintext;
$color_share_a:			$color_maintext;
$color_posts_a:			$color_maintext;
$color_posts_a_visited:		#666666;
//
$margin_global:			1em;
$margin_indent:			$margin_global;	// Indentation of img/table/code etc
$margin_postbody:		$margin_global;	// Margin for main post body
$margin_v:			$margin_global;	// General vertical margin

//
// Font and background defaults for <body>
//
body {
  background: $color_background;
  color: $color_maintext;
  font-family: Ubuntu, sans-serif;
  font-weight: 400;
}
code {
  font-family: 'Ubuntu Mono', monospace;
}

//
// Put back defaults previously removed by reset.css
//
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
em {
  font-style: italic;
}
ol {
  list-style-type: decimal;
}
strong {
  font-weight: bold;
}
ul {
  list-style-type: disc;
}
h1, h2, h3, h4, h5, h6 {
  line-height: 1.2em;
}
h1 {
  border-bottom: 2px solid #e7e7e7;
  font-size: 2em;
}
h2 {
  font-size: 1.6em;
}
h3 {
  font-size: 1.5em;
}
h4 {
  font-size: 1.4em;
}
h5 {
  font-size: 1.3em;
}
h6 {
  font-size: 1.2em;
}
hr {
  margin: 0px 80px;
}

//
// `content' is the main layout holder for all viewable elements
//
#content {
  margin: 20px auto;
  width: 760px;
}

//
// `banner' contains the main page title and some static links
//
// Make the main title large, links smaller, don't underline links, and change
// the colour of links slightly when hovered.
//
#banner {
  font-family: Ubuntu, sans-serif;
  font-weight: 400;
  padding-bottom: 20px;
  text-align: right;
}
#bannertitle {
  font-size: 24px;
}
#bannertitle a {
  color: $color_bannertitle;
}
#bannertitle a:hover {
  text-decoration: none;
}
#bannerlinks {
  font-size: 12px;
}
#bannerlinks a {
  color: $color_bannerlinks;
}

/*
 * #post* contain the actual content
 */
#post {
  padding-bottom: 20px;
}
#postinfo {
  color: $color_postinfo;
  font-family: Ubuntu, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 0.2em 0;
}
#postinfo a {
  color: $color_postinfo_a;
}
#postinfo a:visited {
  color: $color_postinfo_a_visited;
}
#postdate {
  float: left;
}
#posttags {
  text-align: right;
}
#postbody {
  line-height: 1.6em;
  margin: $margin_postbody;
}
#postbody a {
  color: $color_postbody_a;
}
#postbody a:visited {
  color: $color_postbody_a_visited;
}
#postbody p {
  margin: $margin_v 0;
}
#postbody p code {
  background-color: rgba(0,0,0,0.04);
  border-radius: 3px;
}
#postbody .postimg {
  text-align: center;
  margin: $margin_v $margin_indent;
}
#postbody img {
  max-width: 100%;
  width: auto;
}
#postbody ol {
  margin: $margin_indent;
}
#postbody ul {
  margin: $margin_indent;
}
#postbody li {
  margin: 0.2em 0;
}
.posttable {
  margin: $margin_v $margin_indent;
}
.posttable table {
  color: $color_table;
  line-height: 1.5em;
  table-layout: fixed;
  width: 100%;
}
.posttable th {
  font-weight: 400;
  text-align: center;
}
.posttable td {
  background: #fafafa;
  border: 1px solid #aaa;
  padding: 5px;
}
table {
  color: $color_table;
  line-height: 1.5em;
  table-layout: fixed;
  width: 100%;
}
th {
  background: #fafafa;
  border: 1px solid #aaa;
  padding: 5px;
  font-weight: bold;
  text-align: center;
}
td {
  border: 1px solid #aaa;
  padding: 5px;
}
tr:nth-child(even) {
  background: #fafafa;
}
tr:nth-child(odd) {
  background: #efefef;
}
#postshare {
  color: $color_share;
  font-family: Ubuntu, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 10px;
  text-align: center;
}
#postshare a {
  color: $color_share_a;
}

/*
 * Post lists
 */
#taggedposts {
  padding: 0px 80px 20px;
}
#allposts {
  background: #eeeeee;
  border: 1px solid #dddddd;
  padding: 0px 80px;
}
#allposts h1 {
  text-align: center;
}
ul.posts {
  line-height: 1.75em;
  list-style-type: none;
  padding: 5px;
}
ul.posts span {
  font-family: 'Ubuntu Mono', monospace;
}
ul.posts a {
  color: $color_posts_a;
}
ul.posts a:visited {
  color: $color_posts_a_visited;
}

/*
 * Syntax highlighting.  Actual colours are loaded from a separate file.
 */
.highlight {
  border: 1px solid #aaaaaa;
  overflow: auto;
  line-height: 1.15em;
  margin: $margin_v $margin_indent;
  width: auto;
}
/*
 * Necessary to maintain a scrollbar on OSX.
 */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.highlight pre {
  margin: 5px;
  overflow: auto;
  width: auto;
}
